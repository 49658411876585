<template>
  <iframe
    class="docs-iframe"
    src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FYNEAQGFmtW7EMimiumuafw%2FAffiliate-buddy-2.0%3Fnode-id%3D233%3A681"
    frameborder="0"
  />
</template>

<script>
export default {
  name: 'Docs'
}
</script>

<style scoped>
.docs-iframe {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px
}
</style>
